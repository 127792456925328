import { mixed, string, number, array, addMethod } from "yup";

function empty() {
	// Funkcja musi być tak zadeklarowana, aby yup poprawnie ją interpretował
	return this.nullable().transform((value, originalValue) => {
		if (this.isType(value)) return value;

		value = String(originalValue).trim() === "" ? null : value;

		return value;
	});
}

addMethod(mixed, "empty", empty);
addMethod(string, "empty", empty);
addMethod(number, "empty", empty);
addMethod(array, "empty", empty);
