import "vuetify/styles";
import pl from "vuetify/lib/locale/pl";
import { createVuetify as _createVuetify } from "vuetify";
import { useLocaleStore } from "@@/stores/useLocaleStore";
import { vuetifySettings } from "@/vuetify";

// FIX dla braku tłumaczenia
pl.timePicker.title = "Wybór czasu";

export const createVuetify = () => {
	return _createVuetify({
		...vuetifySettings,
		...{
			locale: {
				locale: useLocaleStore()?.getLocale,
				fallback: useLocaleStore()?.getDefaultLocale.slug,
				messages: { pl },
			},
			date: {
				locale: {
					[useLocaleStore()?.getLocale]: useLocaleStore()?.getLocaleObject.iso,
				},
			},
		},
	});
};

export default createVuetify;
