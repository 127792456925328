import env from "@/env";
import { repositoryClient } from "@@/repositories/ClientAxios";
import { useLoadingStore } from "@@/stores/useLoadingStore";
import { useLocaleStore } from "@@/stores/useLocaleStore";
import useRouter from "@@/composables/route/useRouter";

const abortControllers = {};

export default () => {
	const baseURL = () => {
		return `${env.API_URL}/${useLocaleStore().getLocale}`;
	};
	const prepareUrl = (url) => {
		if (!url) {
			return;
		}

		if (url.indexOf("http://") === -1 && url.indexOf("https://") === -1) {
			return `${baseURL()}/${url}`;
		}

		return url;
	};

	const getAbortController = (url) => {
		if (!url) {
			return null;
		}

		if (abortControllers && abortControllers[url]) {
			abortControllers[url].abort(url);
			useLoadingStore().requestStop(url);
			delete abortControllers[url];
		}
		abortControllers[url] = new AbortController();

		return abortControllers[url];
	};

	const get = (url, params = null, abort = true, loadingSilent = false, raw = false) => {
		url = prepareUrl(url);

		let theUrl = url;

		let queryUrl = "";
		if (!raw) {
			const { getQuery } = useRouter();

			queryUrl = new URLSearchParams(getQuery()).toString();

			if (queryUrl) theUrl += (url.includes("?") ? "&" : "?") + queryUrl;
		}

		let data = {};
		if (null !== params) data.params = params;

		if (true === abort) {
			const abortController = getAbortController(url);
			data.signal = abortController.signal;
		}

		if (loadingSilent) useLoadingStore().setRequestSilent(theUrl);

		return new Promise((resolve, reject) => {
			repositoryClient
				.get(theUrl, data)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const getRaw = (url, params = null, abort = true, loadingSilent = false) => {
		return get(url, params, abort, loadingSilent, true);
	};

	const post = (url, data = {}, abort = false, postConfig = {}, loadingSilent = false) => {
		url = prepareUrl(url);

		if (true === abort) {
			const abortController = getAbortController(url);
			data.signal = abortController.signal;
		}

		if (loadingSilent) useLoadingStore().setRequestSilent(url);

		return new Promise((resolve, reject) => {
			repositoryClient
				.post(`${url}`, data, postConfig)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const put = (url, data = {}, loadingSilent = false) => {
		url = prepareUrl(url);

		if (loadingSilent) useLoadingStore().setRequestSilent(url);

		return new Promise((resolve, reject) => {
			repositoryClient
				.put(`${url}`, data)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const del = (url, data = {}, loadingSilent = false) => {
		url = prepareUrl(url);

		if (loadingSilent) useLoadingStore().setRequestSilent(url);

		return new Promise((resolve, reject) => {
			repositoryClient
				.delete(`${url}`, {
					data: data,
				})
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const getOne = (url, id) => {
		url += id ? "/" + id : "";
		return getRaw(url);
	};

	const create = (url, data) => {
		return post(`${url}`, data);
	};

	const update = (url, id, data) => {
		url += id ? "/" + id : "";
		return put(`${url}`, data);
	};

	const deleteOne = (url, id) => {
		url += id ? "/" + id : "";
		return del(`${url}`);
	};

	return {
		baseURL,
		get,
		getRaw,
		post,
		put,
		del,
		getOne,
		create,
		update,
		deleteOne,
	};
};
